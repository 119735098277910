import bootIntercom from "../helpers/bootIntercom";
import checkForPartenaCookieAndShowLogo from "../helpers/checkForPartenaCookieAndShowLogo";
import googleAnalyticsInit from "../helpers/googleAnalyticsInit";
import hotjarInit from "../helpers/hotjarInit";
import setSodalisCookie from "../helpers/setSodalisCookie";

// this code will run on all pages
export default function all() {
  googleAnalyticsInit();

  hotjarInit();

  bootIntercom();

  checkForPartenaCookieAndShowLogo();

  setSodalisCookie();
}
