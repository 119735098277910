export default function () {
  let hotjarInitScript = document.createElement("script");
  hotjarInitScript.append(
    "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:3312484,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
  );
  // read more about how to disable the init of ga with finsweet cookies https://www.finsweet.com/cookie-consent/
  hotjarInitScript.setAttribute("type", "fs-cc");
  hotjarInitScript.setAttribute("fs-cc-categories", "analytics");

  document.body.appendChild(hotjarInitScript);
}
