export default function dashboardHome() {
  console.log("/dashboard/home code executing");

  // in this section we populate the iframe with the glideSessionId and the gaSessionId
  (async () => {
    // get the gaSessionId
    const gaSessionId = await new Promise((resolve) => {
      gtag("get", "G-18ZZXF30B4", "session_id", resolve);
    });

    const gaClientId = await new Promise((resolve) => {
      gtag("get", "G-18ZZXF30B4", "client_id", resolve);
    });
    console.log("gaSessionId: ", gaSessionId);
    console.log("gaClientId: ", gaClientId);

    // if a iframeUrl parameter is present, redirect to this page
    const urlParams = new URLSearchParams(window.location.search);
    const iframeUrl = urlParams.get("iframeUrl");

    let src = "";
    if (iframeUrl) {
      src = `${iframeUrl}?&gaSessionId=${gaSessionId}&gaClientId=${gaClientId}`;
    } else {
      console.log("no iframeUrl provided, going to base url")
      src = `https://app.istart.be?&gaSessionId=${gaSessionId}&gaClientId=${gaClientId}`;
    }

    // create the src for the iframe and append it to the iframe
    console.log("src for iframe: ", src);
    top.document.getElementById("myiframe").setAttribute("src", src);
  })();
}
