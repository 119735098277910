import getEnvironment from "./getEnvironment";

export default function () {
  let intercomAppId = "";

  const environment = getEnvironment();
  if (environment === "local") {
    intercomAppId = "sfzn80ow";
  } else if (environment === "develop") {
    intercomAppId = "sfzn80ow";
  } else {
    intercomAppId = "noazre9b";
  }
  return intercomAppId;
}
