import Cookies from "js-cookie";

export default function setSodalisCookie() {
  var sodalisLogo = document.getElementById("sodalis-logo");
  // get queryparameters from url
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get("utm_sociaal_secretariaat") === "sodalis") {
    // set sodalis cookie
    Cookies.set("utm_sociaal_secretariaat", "sodalis", { expires: 1 });
  }

  // get cookie
  const sociaalSecretariaatCookie = Cookies.get("utm_sociaal_secretariaat");

  // only if cookies are not present, set them
  if (sociaalSecretariaatCookie === "sodalis") {
    sodalisLogo.classList.remove("hidden")
  }
}
