export default function () {
  let gaInitScript = document.createElement("script");
  gaInitScript.setAttribute(
    "src",
    "https://www.googletagmanager.com/gtag/js?id=G-QZP2L1K83H"
  );
  // read more about how to disable the init of ga with finsweet cookies https://www.finsweet.com/cookie-consent/
  gaInitScript.setAttribute("type","fs-cc")
  gaInitScript.setAttribute("fs-cc-categories", "analytics")

  document.body.appendChild(gaInitScript);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }

  gtag("js", new Date());
  gtag("config", "G-QZP2L1K83H");
}
